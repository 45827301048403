<script setup lang="ts">
import type { HomeBanners } from "@/types";

const { t } = useT();
const router = useRouter();

type TournamentBannerType = HomeBanners[number];

defineProps<{ banner: TournamentBannerType }>();

const { open } = useTaoModals();

const { activeStatus, subscribedStatus, loadingSubscribtions, handleTournament } = useTournamentData({ open });

const textBtn = computed(() => {
	if (!activeStatus.value) {
		return t("About tournaments");
	}
	return subscribedStatus.value ? t("Play now") : t("Join now");
});

const handleClick = () => {
	if (!activeStatus.value) {
		router.push("/tournaments/");
		return;
	}
	handleTournament();
};
</script>

<template>
	<MBanner
		class="tournament-banner"
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
	>
		<template #default>
			<AText v-if="banner.title" :size="24" :modifiers="['uppercase', 'semibold']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<AText v-if="banner.smallTitle" class="small-title" :size="20" :modifiers="['uppercase', 'italic', 'medium']">
				<p v-html="banner.smallTitle" />
			</AText>
			<MPrizeFund v-if="banner.subTitle" variant="entries" icon="12/secret-coins" :icon-size="28" :offset="1">
				<AText class="color-gray-50" :size="36" :modifiers="['bold']">
					<p v-html="banner.subTitle" />
				</AText>
			</MPrizeFund>
		</template>

		<template #actions>
			<AButton variant="primary" size="md" class="app-banner__btn" @click="handleClick">
				<AAnimationSpin v-if="loadingSubscribtions">
					<NuxtIcon name="24/loader" filled />
				</AAnimationSpin>
				<template v-else>{{ textBtn }}</template>
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.tournament-banner {
	.small-title {
		margin: -8px 0 8px;

		@include media-breakpoint-down(lg) {
			margin: 4px 0;
		}
	}
}
</style>
